import Link from "next/link";
import React, { useState } from "react";
import {
  Menu,
  Dropdown,
  MenuItemProps,
  Sidebar,
  Button,
  Divider,
  Icon,
} from "semantic-ui-react";
import { NavRoute } from "./useNavRoutes";
import styles from "./NavbarMenu.module.css";

interface NavbarMenuProps {
  routes: NavRoute[];
  sidebar: boolean;
}

const MenuItemSpan = (p: MenuItemProps) => <Menu.Item {...p} as="span" />;

export const NavbarMenu: React.FC<NavbarMenuProps> = ({ routes, sidebar }) => {
  const [visible, setVisible] = useState(false);

  const [collapsibleOpen, setCollapsibleOpen] = useState<string[]>([]);

  const handleCollabsileClick = (id: string) => {
    if (collapsibleOpen.includes(id)) {
      return setCollapsibleOpen(collapsibleOpen.filter((i) => i !== id));
    }

    setCollapsibleOpen([...collapsibleOpen, id]);
  };

  const isCollapsibleOpen = (route: NavRoute) => {
    if (!route.collapsible) return true;

    const isOpen = collapsibleOpen.includes(route.id);
    if (isOpen) return true;

    return false;
  };

  const onHide = () => setVisible(false);

  if (sidebar) {
    return (
      <React.Fragment>
        <Menu.Menu position="right">
          <Menu.Item
            as={MenuItemSpan}
            icon="bars"
            onClick={() => {
              setVisible(true);
            }}
          />
        </Menu.Menu>
        <Sidebar
          as={Menu}
          visible={visible}
          onHide={onHide}
          direction="right"
          animation="overlay"
          vertical
          size="big"
          divided
          className={styles.navbarMenuMobile}
        >
          <Menu.Item>
            <Button
              icon="close"
              basic
              onClick={onHide}
              floated="right"
              size="mini"
            />
          </Menu.Item>

          {routes.map((route) => {
            const isMultiLevel = route.items.length > 0;

            if (!isMultiLevel) {
              return (
                <Link key={route.id} href={route.href!} passHref>
                  <Menu.Item as="a">
                    {route.icon} {route.content}
                  </Menu.Item>
                </Link>
              );
            }

            return (
              <Menu.Item key={route.id}>
                <Menu.Header
                  content={
                    <>
                      {route.collapsible && (
                        <>
                          {isCollapsibleOpen(route) ? (
                            <Icon name="caret down" />
                          ) : (
                            <Icon name="caret right" />
                          )}
                        </>
                      )}
                      {route.content || route.icon}
                    </>
                  }
                  onClick={() => handleCollabsileClick(route.id)}
                />
                <Divider />
                {isCollapsibleOpen(route) &&
                  route.items.map((innerRoute) => {
                    return (
                      <Link
                        key={innerRoute.id}
                        href={innerRoute.href!}
                        passHref
                      >
                        <Menu.Item as="a">
                          {innerRoute.icon} {innerRoute.content}
                        </Menu.Item>
                      </Link>
                    );
                  })}
              </Menu.Item>
            );
          })}
        </Sidebar>
      </React.Fragment>
    );
  }

  return (
    <Menu.Menu position="right">
      {routes.map((route) => {
        const isMultiLevel = route.items.length > 0;

        if (!isMultiLevel) {
          return (
            <Link key={route.id} href={route.href!} passHref>
              <Menu.Item as="a" content={route.content} icon={route.icon} />
            </Link>
          );
        }

        return (
          <Dropdown
            key={route.id}
            as={MenuItemSpan}
            trigger={
              <>
                {route.icon}
                {route.content}
              </>
            }
          >
            <Dropdown.Menu>
              {route.items.map((innerRoute) => {
                return (
                  <Link key={innerRoute.id} href={innerRoute.href!} passHref>
                    <Dropdown.Item
                      as="a"
                      text={innerRoute.content}
                      icon={innerRoute.icon}
                    />
                  </Link>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        );
      })}
    </Menu.Menu>
  );
};
