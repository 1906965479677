import clsx from "clsx";
import Head from "next/head";
import Router from "next/router";
import NProgress from "nprogress";
import React, { ReactNode, useEffect } from "react";
import { Container } from "semantic-ui-react";
import { Footer, FooterProps } from "./Footer";
import styles from "./Layout.module.css";
import Navbar from "./Navbar";
import { Container as MContainer, Loader, Center } from "@mantine/core";

export type LayoutServerProps = {
  footerProps?: FooterProps | null;
};

type LayoutProps = {
  children?: ReactNode;
  headChildren?: ReactNode;
  title?: string;
  noFooter?: boolean;
  py?: number;
  px?: number;
  loadingFallback?: boolean;
} & LayoutServerProps;

export const Layout = React.memo(
  ({
    children,
    title = "Listatto.com",
    headChildren,
    noFooter,
    py = 65,
    px,
    footerProps,
    loadingFallback,
  }: LayoutProps) => {
    useEffect(() => {
      NProgress.configure({
        showSpinner: false,
        minimum: 0.5,
        trickleSpeed: 150,
      });
      Router.events.on("routeChangeStart", () => NProgress.start());
      Router.events.on("routeChangeComplete", () => NProgress.done());
      Router.events.on("routeChangeError", () => NProgress.done());
    }, []);

    const content = loadingFallback ? <LayoutLoadingFallback /> : children;

    return (
      <div>
        <Head>
          <title>{title}</title>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
          <link rel="shortcut icon" href="/images/icon.png" />
          {headChildren}
        </Head>
        <header>
          <Navbar />
        </header>

        <Container
          fluid
          className={clsx(styles.layoutContainer)}
          style={{
            paddingTop: py,
            paddingBottom: py,
            paddingLeft: px,
            paddingRight: px,
          }}
        >
          {content}
        </Container>
        {!noFooter && footerProps && (
          <footer>
            <Footer {...footerProps} />
          </footer>
        )}
      </div>
    );
  }
);

function LayoutLoadingFallback() {
  return (
    <>
      <Center h={"50vh"}>
        <Loader color="red" variant="bars" size={"xl"}></Loader>
      </Center>
    </>
  );
}

export default Layout;
