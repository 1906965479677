import { NextRouter } from "next/router";
import React, { useState } from "react";
import {
  RiBuilding2Line,
  RiChat3Line,
  RiHeart2Line,
  RiHome2Line,
  RiLock2Line,
  RiLogoutBoxLine,
  RiMapPinTimeLine,
  RiMoneyDollarCircleLine,
  RiMore2Line,
  RiPriceTag3Line,
  RiSearchLine,
  RiUserLine,
} from "react-icons/ri";
import { Icon, Image } from "semantic-ui-react";

import { AccountStore } from "../../stores/AccountStore";
import { getPathnameSearch } from "../../util/getPathnameSearch";

export enum NavCondition {
  Protected = "protected",
  Public = "public",
  PublicOnly = "public-only",
}

export interface NavRoute {
  id: string;
  content: string;
  icon: React.ReactNode;
  href: string | null;
  items: NavRoute[];
  condition?: NavCondition;
  collapsible?: boolean;
}

// @ts-ignore
const AvatarTrigger = ({ isLoggedIn, lastProviderLogin, user }) => {
  const [avatarError, setAvatarError] = useState(false);
  let avatar = <RiUserLine />;
  const imgSrc =
    user?.email && user.lastProviderLogin !== "local"
      ? user?.photos?.filter(
          // @ts-ignore
          (photo) => photo.provider === user?.lastProviderLogin
        )[0].value
      : null;

  if (!isLoggedIn) {
    return (
      <React.Fragment>
        <Icon name="bars" />
      </React.Fragment>
    );
  }

  if (isLoggedIn && lastProviderLogin !== "local") {
    if (!avatarError) {
      avatar = (
        <Image
          src={imgSrc}
          avatar
          onError={() => {
            setAvatarError(true);
          }}
        />
      );
    }
  }

  return (
    <React.Fragment>
      <span style={{ marginRight: 5 }}>
        {user?.firstName ? user.firstName : "Me"}
      </span>
      {avatar}
    </React.Fragment>
  );
};

const filterNavRoutes = (routes: NavRoute[], isLoggedIn: boolean) => {
  return routes.reduce((acc, route) => {
    if (route.condition === NavCondition.Protected && !isLoggedIn) return acc;

    if (route.condition === NavCondition.PublicOnly && isLoggedIn) return acc;
    route.items = filterNavRoutes(route.items, isLoggedIn);
    acc.push(route);
    return acc;
  }, [] as NavRoute[]);
};

interface UseNavRoutesArgs {
  account: AccountStore;
  router: NextRouter;
}

export const useNavRoutes = (opts: UseNavRoutesArgs) => {
  const { account, router } = opts;
  const { user, isLoggedIn } = account;

  const routes: NavRoute[] = [
    {
      id: "home",
      content: "Home",
      icon: null,
      href: "/",
      items: [],
    },
    {
      id: "for-rent",
      content: "For Rent",
      icon: <RiMapPinTimeLine size={20} style={{ marginRight: 5 }} />,
      href: null,
      items: [
        {
          id: "properties-for-rent",
          content: "All Properties For Rent",
          icon: (
            <Icon>
              <RiSearchLine />
            </Icon>
          ),
          href: getPathnameSearch(router, "/search/properties-for-rent"),
          items: [],
        },
        {
          id: "homes-for-rent",
          content: "Homes For Rent",
          icon: (
            <Icon>
              <RiHome2Line />
            </Icon>
          ),
          href: getPathnameSearch(router, "/search/homes-for-rent"),
          items: [],
        },
        {
          id: "condos-for-rent",
          content: "Condos For Rent",
          icon: (
            <Icon>
              <RiBuilding2Line />
            </Icon>
          ),
          href: getPathnameSearch(router, "/search/condos-for-rent"),
          items: [],
        },
      ],
    },
    {
      id: "for-sale",
      content: "For Sale",
      icon: <RiPriceTag3Line size={20} style={{ marginRight: 5 }} />,
      href: null,
      items: [
        {
          id: "properties-for-sale",
          content: "All Properties For Sale",
          icon: (
            <Icon>
              <RiSearchLine />
            </Icon>
          ),
          href: getPathnameSearch(router, "/search/properties-for-sale"),
          items: [],
        },
        {
          id: "homes-for-sale",
          content: "Homes For Sale",
          icon: (
            <Icon>
              <RiHome2Line />
            </Icon>
          ),
          href: getPathnameSearch(router, "/search/homes-for-sale"),
          items: [],
        },
        {
          id: "condos-for-sale",
          content: "Condos For Sale",
          icon: (
            <Icon>
              <RiBuilding2Line />
            </Icon>
          ),
          href: getPathnameSearch(router, "/search/condos-for-sale"),
          items: [],
        },
      ],
    },
    {
      id: "sold",
      content: "Sold",
      icon: <RiMoneyDollarCircleLine size={20} style={{ marginRight: 5 }} />,
      href: null,
      items: [
        {
          id: "properties-sold",
          content: "All Properties Sold",
          icon: (
            <Icon>
              <RiSearchLine />
            </Icon>
          ),
          href: getPathnameSearch(router, "/search/properties-sold"),
          items: [],
        },
        {
          id: "homes-sold",
          content: "Homes Sold",
          icon: (
            <Icon>
              <RiHome2Line />
            </Icon>
          ),
          href: getPathnameSearch(router, "/search/homes-sold"),
          items: [],
        },
        {
          id: "condos-sold",
          content: "Condos Sold",
          icon: (
            <Icon>
              <RiBuilding2Line />
            </Icon>
          ),
          href: getPathnameSearch(router, "/search/condos-sold"),
          items: [],
        },
      ],
    },
    {
      id: "more",
      content: "More",
      icon: <RiMore2Line size={20} style={{ marginRight: 5 }} />,
      href: null,
      collapsible: true,
      items: [
        {
          id: "building-profiles",
          content: "Building Profiles",
          icon: null,
          href: "/content/building-profiles",
          items: [],
        },
        {
          id: "blog",
          content: "Blog",
          icon: null,
          href: "/content/blog",
          items: [],
        },
        {
          id: "international-renter",
          content: "International Renters",
          icon: null,
          href: "https://listatto.com/international-renter",
          items: [],
        },
      ],
    },
    {
      id: "account",
      content: "",
      icon: (
        <AvatarTrigger
          user={user}
          isLoggedIn={isLoggedIn}
          lastProviderLogin={user?.lastProviderLogin}
        />
      ),
      href: null,
      condition: NavCondition.Protected,
      items: [
        {
          id: "my-searches",
          content: "My Searches",
          icon: (
            <Icon>
              <RiSearchLine />
            </Icon>
          ),
          href: "/account/searches",
          items: [],
        },
        {
          id: "my-likes",
          content: "My Likes",
          icon: (
            <Icon>
              <RiHeart2Line />
            </Icon>
          ),
          href: "/account/likes",
          items: [],
        },
        {
          id: "my-inquiries",
          content: "My Inquiries",
          icon: (
            <Icon>
              <RiChat3Line />
            </Icon>
          ),
          href: "/account/inquiries",
          items: [],
        },
        {
          id: "my-profile",
          content: "My Profile",
          icon: (
            <Icon>
              <RiChat3Line />
            </Icon>
          ),
          href: "/account/edit-profile",
          items: [],
        },
        {
          id: "logout",
          content: "Logout",
          icon: (
            <Icon>
              <RiLogoutBoxLine />
            </Icon>
          ),
          href: "/logout",
          items: [],
        },
      ],
    },
    {
      id: "register",
      content: "Login",
      icon: (
        <Icon>
          <RiLock2Line />
        </Icon>
      ),
      condition: NavCondition.PublicOnly,
      href: process.browser
        ? `/register?redirect=${document.location.href}`
        : "",
      items: [],
    },
  ];

  return filterNavRoutes(routes, isLoggedIn);
};
