import { NextRouter } from "next/router";

export const getPathnameSearch = (router: NextRouter, baseUrl: string) => {
  let { area } = router.query;
  let search = "";
  if (process.browser) {
    search = document.location.search;
  }
  if (!Array.isArray(area)) area = [];

  if (area.length) return baseUrl + "/" + area.join("/") + search;

  return baseUrl;
};
