import { observer } from "mobx-react-lite";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { Image, Menu } from "semantic-ui-react";
import { fetchItem } from "api/directus";

import { useIsMobile } from "../../hooks/use-is-mobile";
import { useAccountStore } from "../../stores/AccountStore";
import styles from "./Navbar.module.css";
import { NavbarMenu } from "./NavbarMenu";
import { useNavRoutes } from "./useNavRoutes";

export const Navbar: React.FC = observer(() => {
  const router = useRouter();
  const account = useAccountStore();
  const isMobile = useIsMobile();
  const routes = useNavRoutes({
    router,
    account,
  });

  const [brokerageLogo, setBrokerageLogo] = useState(
    typeof window !== "undefined"
      ? localStorage.getItem("brokerage_logo")
      : null
  );

  useEffect(() => {
    (async () => {
      const { data } = await fetchItem<{
        brokerage_logo: {
          filename_disk: string;
        };
      }>("logos", { fields: "*.*" });

      if (data?.brokerage_logo) {
        localStorage.setItem(
          "brokerage_logo",
          data.brokerage_logo.filename_disk
        );

        if (brokerageLogo !== data.brokerage_logo.filename_disk) {
          setBrokerageLogo(data.brokerage_logo.filename_disk);
        }
      } else {
        localStorage.removeItem("brokerage_logo");
        setBrokerageLogo(null);
      }
    })();
  }, [brokerageLogo]);

  return (
    <Menu fixed="top" className={styles.navbar}>
      <Link href="/" passHref>
        <Menu.Item as="a" className={styles.logo}>
          <Image
            src={"/images/icon.png"}
            width={48}
            height={48}
            alt="Listatto Logo"
          />
        </Menu.Item>
      </Link>
      {brokerageLogo && (
        <Link href="/" passHref>
          <Menu.Item
            as="a"
            style={{
              padding: "5px 25px",
            }}
          >
            <Image
              src={process.env.NEXT_PUBLIC_CDN_HOST + "/" + brokerageLogo}
              height={40}
              alt="BHHS Logo"
              style={{ height: 40 }}
            />
          </Menu.Item>
        </Link>
      )}

      <NavbarMenu routes={routes} sidebar={isMobile} />
    </Menu>
  );
});

export default Navbar;
