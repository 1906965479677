import { Directus, QueryMany } from "@directus/sdk";

export const directus = new Directus(
  `${process.env.NEXT_PUBLIC_API_HOST}/api/content`
);

type DirectusError = {
  message: string;
  extensions: {
    code: string;
  };
};

export type DirectusResponse<T> = {
  meta?: {
    filter_count?: number;
    total_count?: number;
  };
  errors?: DirectusError[] | null;
  data?: T | null;
};

export async function fetchItems<T extends Record<string, any>>(
  collection: string,
  q: QueryMany<T>
): Promise<DirectusResponse<T[]>> {
  let ret = {
    errors: null,
  };
  try {
    const res = await directus.items<string, T>(collection).readByQuery(q);
    Object.assign(ret, res);
  } catch (err) {
    // @ts-ignore
    Object.assign(ret, err?.response?.data);
  }

  return ret;
}

export async function fetchItem<T extends Record<string, any>>(
  collection: string,
  q: QueryMany<T>
): Promise<DirectusResponse<T>> {
  const res = await fetchItems(collection, q);
  if (Array.isArray(res.data)) {
    return res.data?.[0];
  }
  return res as T;
}
