import { useState, useEffect, useCallback } from "react";
import { Responsive } from "semantic-ui-react";

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(
    process.browser && Responsive.onlyMobile.maxWidth! >= window.innerWidth
  );

  const doResize = useCallback(() => {
    if (Responsive.onlyMobile.maxWidth! >= window?.innerWidth) {
      setIsMobile(true);
    } else {
      if (isMobile) {
        setIsMobile(false);
      }
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      doResize();
    });
    window.addEventListener("resize", doResize);
    return () => window.removeEventListener("resize", doResize);
  }, []);

  return isMobile;
};
